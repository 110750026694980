<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="riskTable"
            title="위험요인 목록"
            :columns="gridColumns"
            :gridHeight="grid.height"
            :data="data.tbmRiskHazardModelList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="위험요인을 추가하세요."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="tbmRiskId"
            selection="multiple"
          >
            <!-- <template v-slot:suffixTitle>
              <q-btn-group outline class="scenario-view-group">
                <q-btn 
                  :outline="scenarioViewGroup!=='L'"
                  :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                  size="11px" label="" icon="toggle_off"
                  @click="listView"
                >
                  <q-tooltip>
                    요약
                  </q-tooltip>
                </q-btn>
                <q-btn 
                  :outline="scenarioViewGroup!=='C'"
                  :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                  size="11px" label="" icon="toggle_on"
                  @click="cardView"
                >
                  <q-tooltip>
                    작업단계-유해요인-안전대책
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </template> -->
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="직접추가"
                  icon="add"
                  @btnClicked="addRisk"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    data.tbmRiskHazardModelList.length > 0
                  "
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeRisk"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmJobStep'">
                <c-text-column
                  v-if="props.row['tbmPermitFlag'] === 'N'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmJobStep']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmJobStep']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmRiskHazard'">
                <c-text-column
                  v-if="props.row['tbmPermitFlag'] === 'N'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmRiskHazard']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmRiskHazard']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmImprovementMeasures'">
                <c-text-column
                  v-if="props.row['tbmPermitFlag'] === 'N'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmImprovementMeasures']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmImprovementMeasures']}}
                </span>
              </template>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
export default {
  name: "tbm-risk-hazard",
  props: {
    popupParam: {
      type: Object,
      default() {
        return {
          tbmId: "",
        };
      },
    },
    data: {
      type: Object,
      default() {
        return {
          tbmId: "",
          tbmNo: "",
          plantCd: "",
          permitNo: "",
          sopName: "",
          mapName: '',
          tbmWorkResponsibleId: "",
          tbmWorkResponsibleName: "",
          tbmName: "",
          tbmWorkDate: "",
          tbmWorkArea: "",
          tbmWorkContents: "",
          significant: "",
          tbmCompleteFlag: 'N',
          processCd: "",
          vendorCd: "",
          sopWorkPermitId: "",
          preInspections: "",
          tbmWorkPermitFlag: 'N',
          companyCd: '',

          tbmAttendeeModelList: [],
          tbmRiskHazardModelList: [],
        };
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
        ],
        height: "500px",
        data: [],
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      getUrl: "",
      saveUrl: "transactionConfig.sai.tbm.insert.url",
      completeUrl: "transactionConfig.sai.tbm.complete.url",
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getRespondUrl: '',
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      redirectUrl: '',
      serverName: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: false,
      scenarioViewGroup: 'L',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isWorkPermit() {
      return (
        Boolean(this.data.sopWorkPermitId) &&
        this.editable
      );
    },
    disabled() {
      return this.data.tbmCompleteFlag === "Y";
    },
    isPlant() {
      return Boolean(!this.data.plantCd)
    },
    isProcess() {
      return Boolean(!this.data.plantCd) || Boolean(!this.data.processCd)
    },
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return true;
      } else {
        return false;
      }
    },
    gridColumns() {
      let cols = [];
      cols = [
        {
          name: "tbmJobStep",
          field: "tbmJobStep",
          label: "작업단계",
          align: "left",
          style: "width:30%",
          type: "text",
          sortable: false,
        },
        {
          name: "tbmRiskHazard",
          field: "tbmRiskHazard",
          label: "위험요인",
          align: "left",
          style: "width:30%",
          type: "text",
          sortable: false,
        },
        {
          name: "tbmImprovementMeasures",
          field: "tbmImprovementMeasures",
          label: "안전대책",
          type: "text",
          style: "width:40%",
          align: "left",
          sortable: false,
        },
      ]
      return cols
    }
  },
  // * 작업허가서 있을때
  // - 프로젝트, 공종, 업체, 작업, 작업책임자(업체감독자), 작업장소, 작업일, 작업내용 전부 disabled
  // - 참석자 : 작업허가서의 작업자
  // * 작업허가서 없을때
  // - 공사현장과 관련된 공종 선택
  // - 작업장소 : 공사현장 > 작업장소
  // - 작업책임자 : 하도급 > 안전책임자
  // - 작업 : 공종, 업체에 맞는 작업 선택
  // - 업체 : 프로젝트와 공종에 맞는 하도급 선택
  // - 위험요인 목록  작업과 관련된 작업단계(직접추가 제외)
  // - 참석자 : 직영일때 사용자 추가(이름, 직책/직무 수정X), 업체일때 행추가(이름, 직책/직무 수정O) 
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    addRisk() {
      this.data.tbmRiskHazardModelList.splice(0, 0, {
        tbmId: this.popupParam.tbmId,
        tbmRiskId: uid(),
        tbmJobStep: "",
        tbmRiskHazard: "",
        tbmImprovementMeasures: "",
        tbmSopFlag: "N",
        tbmJsaFlag: "N",
        tbmDirectFlag: "Y",
        tbmPermitFlag: "N",
        regUserId: this.$store.getters.user.userId,
        remarks: "",
        editFlag: "C",
        type: '직접입력'
      });
    },
    removeRisk() {
      let selectData = this.$refs["riskTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "하나 이상 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.data.deleteTbmRiskHazardModelList) {
            this.data.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteTbmRiskHazardModelList.push(item);
          }
          this.data.tbmRiskHazardModelList = this.$_.reject(
            this.data.tbmRiskHazardModelList,
            item
          );
        });
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  },
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>