var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "riskTable",
                  attrs: {
                    title: "위험요인 목록",
                    columns: _vm.gridColumns,
                    gridHeight: _vm.grid.height,
                    data: _vm.data.tbmRiskHazardModelList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "위험요인을 추가하세요.",
                    hideBottom: true,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmRiskId",
                    selection: "multiple",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "tbmJobStep"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmJobStep"],
                                        callback: function ($$v) {
                                          _vm.$set(props.row, "tbmJobStep", $$v)
                                        },
                                        expression: "props.row['tbmJobStep']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmJobStep"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmRiskHazard"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmRiskHazard"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmRiskHazard",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmRiskHazard']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmRiskHazard"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmImprovementMeasures"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value:
                                          props.row["tbmImprovementMeasures"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmImprovementMeasures",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmImprovementMeasures']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row["tbmImprovementMeasures"]
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "직접추가",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addRisk },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.data.tbmRiskHazardModelList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "제외",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeRisk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }